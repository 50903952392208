<template>
  <v-card class="shadow-0 bordered" >
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <v-toolbar  elevation="0" style="background-color: #F8FAFB">
        <v-btn
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
          icon
          rounded
        >
          <v-icon>mdi-format-bold</v-icon>
        </v-btn>

        <v-btn
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
          icon
          rounded
        >
          <v-icon>mdi-format-italic</v-icon>
        </v-btn>

        <v-btn
          :class="{ 'is-active': isActive.strike() }"
          @click="commands.strike"
          icon
          rounded
        >
          <v-icon>mdi-format-strikethrough</v-icon>
        </v-btn>

        <v-btn
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
          icon
          rounded
        >
          <v-icon>mdi-format-underline</v-icon>
        </v-btn>

        <v-btn
          :class="{ 'is-active': isActive.code() }"
          @click="commands.code"
          icon
          rounded
        >
          <v-icon>mdi-code-tags</v-icon>
        </v-btn>

        <v-btn
          :class="{ 'is-active': isActive.paragraph() }"
          @click="commands.paragraph"
          icon
          rounded
        >
          <v-icon>mdi-format-paragraph</v-icon>
        </v-btn>

        <v-btn
          :class="{ 'is-active': isActive.heading({ level: 1 }) }"
          @click="commands.heading({ level: 1 })"
          icon
          rounded
        >
          <v-icon>mdi-format-header-1</v-icon>
        </v-btn>

        <v-btn
          :class="{ 'is-active': isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })"
          icon
          rounded
        >
          <v-icon>mdi-format-header-2</v-icon>
        </v-btn>

        <v-btn
          :class="{ 'is-active': isActive.heading({ level: 3 }) }"
          @click="commands.heading({ level: 3 })"
          icon
          rounded
        >
          <v-icon>mdi-format-header-3</v-icon>
        </v-btn>

        <v-btn
          :class="{ 'is-active': isActive.bullet_list() }"
          @click="commands.bullet_list"
          icon
          rounded
        >
          <v-icon>mdi-format-list-bulleted</v-icon>
        </v-btn>

        <v-btn
          :class="{ 'is-active': isActive.ordered_list() }"
          @click="commands.ordered_list"
          icon
          rounded
        >
          <v-icon>mdi-format-list-numbered</v-icon>
        </v-btn>

        <v-btn
          :class="{ 'is-active': isActive.blockquote() }"
          @click="commands.blockquote"
          icon
          rounded
        >
          <v-icon>mdi-format-quote-close</v-icon>
        </v-btn>

        <v-btn
          class="menubar__button"
          @click="showImagePrompt(commands.image)"
          icon
          rounded
        >
          <v-icon>mdi-image</v-icon>
        </v-btn>

        <v-btn @click="commands.undo" icon rounded>
          <v-icon>mdi-undo</v-icon>
        </v-btn>

        <v-btn @click="commands.redo" icon rounded>
          <v-icon>mdi-redo</v-icon>
        </v-btn>
      </v-toolbar>
    </editor-menu-bar>
    <v-card-text
      style="max-height: 250px"
      class="overflow-y-auto overflow-x-hidden headline no-background"
    >
      <editor-content class="editor__content" :editor="editor" />
    </v-card-text>
  </v-card>
</template>

<script>
import {
  Editor,
  EditorContent,
  EditorMenuBar,
  // EditorMenuBubble
} from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Image,
} from "tiptap-extensions";
export default {
  props: { message: { type: String, default: ""} },
  components: {
    EditorContent,
    EditorMenuBar,
    // EditorMenuBubble,
  },
  mounted() {
    if (this.message != "") {
      this.editor.setContent(this.message);
    }
  },
  watch: {
    message(val) {
      if (val != "") {
        this.editor.setContent(val);
      }
    },
  },
  data() {
    return {
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Image(),
        ],
        content: ``,
        onUpdate: ({ getHTML }) => {
          this.$emit("complete", getHTML());
        },
        parseOptions: {
          preserveWhitespace: true,
        },
      }),
      linkUrl: null,
      linkMenuIsActive: false,
    };
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    showImagePrompt(command) {
      const src = prompt("Enter the url of your image here");
      if (src !== null) {
        command({ src });
      }
    },
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href;
      this.linkMenuIsActive = true;
      this.$nextTick(() => {
        this.$refs.linkInput.focus();
      });
    },
    hideLinkMenu() {
      this.linkUrl = null;
      this.linkMenuIsActive = false;
    },
    setLinkUrl(command, url) {
      command({ href: url });
      this.hideLinkMenu();
    },
  },
};
</script>
<style scoped>
/deep/ .ProseMirror:focus {
  outline: none;
}
/deep/.ProseMirror {
  min-height: 200px;
}
</style>
